<template>
  <div class="container">
    <div class="forgetRow" type="flex" justify="space-between" align="middle">
      <div class="logo">
        <img :src="initLogo" style="height:40px;" />
        <span v-if="!onlyvalidemail">{{$t('forget.findPassword')}}</span>
      </div>
      <span class="actionLink">
        <!-- <span @click="$router.push('/register')" v-if="false">{{$t('forget.register')}}</span>
        <span class="split">|</span> -->
        <span @click="$router.push('/login')">{{$t('forget.login')}}</span>
      </span>
    </div>
    <el-main>
      <div class="div-content">
        <p class="tip">{{accountType == 1 ? $t('forget.pleaseInputYourMobile') : $t('forget.pleaseInputEmail')}}</p>
        <el-form label-width="10em" label-position="right" :rules="rules" :model="form">
          <el-form-item :label="$t('forget.mobile')" v-if="accountType===1">
            <el-input v-model.trim="mobile" style="width:240px;" :maxlength="11"
              oninput="value=value.replace(/[^\d]/g, '')"></el-input>
            <span class="changeAccout" @click="accountType = 1 - accountType">{{$t('forget.email')}}</span>
          </el-form-item>
          <el-form-item :label="$t('forget.email')" prop="email" key="email" v-else>
            <el-input v-model.trim="email" :placeholder="$t('forget.pleaseInputEmail')" style="width: 240px;"
              :maxlength="32"></el-input>
            <span class="changeAccout" @click="accountType = 1 - accountType"
              v-if="$i18n.locale == 'zh'">{{$t('forget.mobile')}}</span>
          </el-form-item>
          <el-form-item :label="$t('register.validCode')" prop="smsCode" key="smsCode" v-if="stage2Visible">
            <el-input v-model.trim="form.smsCode" :placeholder="$t('register.validCode')" style="width: 240px;"
              :maxlength="4" oninput="value=value.replace(/[^\d]/g, '')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('register.password')" prop="password" key="password" v-if="stage2Visible">
            <el-input v-model="form.password" :placeholder="$t('register.inputNewPassword')" type="password"
              style="width: 240px;" :maxlength="32"></el-input>
          </el-form-item>
          <el-form-item :label="$t('forget.valid')">
            <div class="valid-wrapper" style="">
              <el-slider v-model="rate" :show-tooltip="false" @change="rateChange" :disabled="rate==100"></el-slider>
              <p class="valid-tip success" v-if="rate==100">{{$t('forget.validSuccess')}}</p>
              <p class="valid-tip" v-else>{{$t('forget.pleaseDrag')}}</p>
            </div>
          </el-form-item>
          <el-form-item v-if="!stage2Visible">
            <el-button @click="findPassword" :loading="loading" :disabled="rate!=100" style="width: 100px;">
              {{$t('common.ok')}}</el-button>
          </el-form-item>
          <el-form-item v-else>
            <el-button @click="resetPassword" :loading="resetLoading" :disabled="rate!=100 || !form.smsCode"
              style="width: 100px;">{{$t('common.ok')}}</el-button>
            <el-button @click="findPassword" :loading="loading" :disabled="rate!=100" type="text">
              {{$t('register.tryAgain')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="div-infomation" v-if="platform === '_kc'">
        <template v-if="$i18n.locale==='fr'">
          <p class="imb-4">Pour plus d'informations ou d'assistance</p>
          <p>Courriel <a href="mailto:contactus@kycs.ca" style="color:#0AA7F9">contactus@kycs.ca</a></p>
          <p>Vous pouvez également nous contacter directement au 1-866-800-4111 poste 3</p>
          <p><a href="//www.kycs.ca" target="_blank" style="color:#0AA7F9">www.kycs.ca</a></p>
          <div style="font-size:12px">
            <p class="imt-3">Notre adresse postale est:</p>
            <p>KYCS Global Inc</p>
            <p>12, chemin Proctor</p>
            <p>2ème étage</p>
            <p>Schomberg, Ontario L0G1T0</p>
            <p>Canada</p>
          </div>
        </template>
        <template v-else>
          <p class="imb-4">For More Information or Assistance</p>
          <p>Email <a href="mailto:contactus@kycs.ca" style="color:#0AA7F9">contactus@kycs.ca</a></p>
          <p>You may also contact us directly at 1-866-800-4111 extension 3</p>
          <p><a href="//www.kycs.ca" target="_blank" style="color:#0AA7F9">www.kycs.ca</a></p>
          <div style="font-size:12px">
            <p class="imt-3">Our mailing address is:</p>
            <p>KYCS Global Inc</p>
            <p>12 Proctor Road</p>
            <p>2nd Floor</p>
            <p>Schomberg, Ontario L0G1T0</p>
            <p>Canada</p>
          </div>
        </template>
      </div>
      <el-dialog :title="$t('forget.resetPassword')" :visible.sync="holdOptionVisible" width="500px">
        <p>{{$t('forget.multiTip')}}</p>
        <el-select v-model="holdId" class="imt-2">
          <el-option v-for="item in holdOptions" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="holdOptionVisible = false">{{$t('common.cancel')}}</el-button>
          <el-button type="primary" @click="resetPassword2" :loading="confirmLoading">{{$t('common.confirm')}}
          </el-button>
        </span>
      </el-dialog>
    </el-main>
  </div>
</template>

<script>
  import {
    getLastLoginInfo
  } from '@/common/cache'
  import {
    BASE_URL
  } from '@/api/config'
  import {
    FindPassword,
    ChangePasswordByCaptcha,
    ChangePasswordByCaptchaV2
  } from '@/api/login'
  export default {
    data() {
      return {
        platform: process.env.VUE_APP_PLAT,
        mobile: '',
        email: '',
        rate: 0,
        loading: false,
        accountType: 1,
        guid: '',
        resetLoading: false,
        stage2Visible: false,
        holdOptionVisible: false,
        holdOptions: [],
        holdId: null,
        confirmLoading: false,
        form: {
          password: '',
          smsCode: ''
        },
        rules: {
          password: [{
            required: true,
            message: this.$t('register.pleaseInputPassword')
          }],
          smsCode: [{
            required: true,
            message: this.$t('register.errorValidCode')
          }]
        }
      }
    },
    created() {
      const lastLoginInfo = getLastLoginInfo()
      this.initLogo = lastLoginInfo.PlatformLogo || `${BASE_URL}/assets/Logo?holdid=1`
      if (this.$i18n.locale != 'zh') {
        this.accountType = 0
      }
    },
    methods: {
      rateChange(val) {
        if (val != 100) {
          this.rate = 0
        }
      },
      findPassword() {
        this.stage2Visible = false
        if (this.accountType === 1 && !this.mobile) {
          return alert(this.$t('forget.pleaseInputYourMobile'))
        } else if (this.accountType === 0 && !this.email) {
          return alert(this.$t('forget.pleaseInputEmail'))
        }

        this.loading = true
        const to = this.accountType === 1 ? this.mobile : this.email
        FindPassword(to, this.accountType).then(ret => {
          this.loading = false
          this.rate = 0
          if (!ret.data.errCode) {
            if (this.accountType == 0) {
              this.guid = ret.data.guid
              if (ret.data.ptype == '1') {
                // 用户
                this.stage2Visible = true
                this.$alert(this.$t('register.validTipEmail'))
              } else {
                // 车辆
                this.stage2Visible = false
                this.$alert(this.$t('forget.findSuccess'))
              }
            } else {
              this.$alert(this.$t('forget.findSuccess'))
            }
          } else {
            this.$alert(this.$t('forget.findError'))
          }
        })
      },
      resetPassword() {
        this.resetLoading = true
        ChangePasswordByCaptcha(this.form.password, this.guid, this.form.smsCode).then(ret => {
          if (!ret.data.ErrCode) {
            this.$alert(this.$t('common.success')).then(() => {
              this.$router.push('/login')
            })
          } else if (ret.data.ErrCode == 50051) {
            this.holdOptions = ret.data.data
            this.holdId = ret.data.data[0].id
            this.holdOptionVisible = true
          } else {
            this.$alert(this.$t('register.errorValidCode'))
          }
        }).finally(() => {
          this.resetLoading = false
        })
      },
      resetPassword2() {
        this.confirmLoading = true
        ChangePasswordByCaptchaV2(this.form.password, this.guid, this.form.smsCode, this.holdId).then(ret => {
          if (!ret.data.ErrCode) {
            this.$alert(this.$t('common.success')).then(() => {
              this.$router.push('/login')
            })
          } else {
            this.$alert(this.$t('register.errorValidCode'))
          }
        }).finally(() => {
          this.confirmLoading = false
        })
      }
    }
  }

</script>

<style lang="scss" scoped>
  @import "../../styles/variables.scss";
  $maxWidth: 75%;
  $formWidth: 480px;

  .forgetRow {
    max-width: $maxWidth;
    padding-top: 40px;
    height: 110px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .logo {
      display: flex;
      line-height: 42px;
      font-size: 14px;
    }

    .actionLink {
      font-weight: 800;
      color: #1890ff;
      line-height: 42px;

      .split {
        color: black;
        margin: 0 4px;
      }

      span:not(.split):hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .tip {
    color: #606266;
    max-width: $formWidth;
    margin: 0 auto;
    margin-bottom: 32px;
    font-size: 14px;
  }

  .div-content {
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 60px;

    .el-form {
      max-width: $formWidth;
      margin: 0 auto;

      & ::v-deep .el-form-item__label {
        padding-right: 24px;
      }
    }

    .valid-wrapper {
      width: 240px;
      padding-right: 32px;
      background-color: #E8E8E8;
      position: relative;

      .valid-tip {
        position: absolute;
        width: 240px;
        top: 0;
        left: 0;
        font-size: 12px;
        height: 38px;
        line-height: 38px;
        // text-align: center;
        text-indent: 35px;
        color: rgb(108, 108, 108);
        z-index: 4;
        user-select: none;

        &.success {
          color: #FFF;
        }
      }
    }

    .el-slider {
      & ::v-deep {
        .el-slider__runway {
          margin: 0;
          height: 38px;
          border-radius: 0;
          background-color: #E8E8E8;

          .el-slider__bar {
            height: 38px;
            background-color: #7AC23C;
          }

          &.disabled {
            .el-slider__bar {
              background-color: #78D02E;
            }
          }
        }

        .el-slider__button-wrapper {
          transform: none;
          height: 38px;
          width: 30px;
          background-color: #FFF;
          top: 1px !important;
          margin-left: 1px;
          outline: none;

          .el-slider__button {
            visibility: hidden;
          }
        }
      }
    }
  }

  .changeAccout {
    color: $--color-primary;
    margin-left: 12px;
    cursor: pointer;
  }

  .div-infomation {
    max-width: 1004px;
    text-align: center;
    margin: 32px auto;
    font-weight: bold;
  }

</style>
